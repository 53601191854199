@media (max-width: 576px) {
  /* xs breakpoint in AntD */
  .xs-top-margin {
    margin-top: 8px;
  }
}

@media (max-width: 768px) {
  /* sm breakpoint in AntD */
  .sm-top-margin {
    margin-top: 8px;
  }
}

.no-wrap-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-table-thead > tr > th {
  white-space: nowrap; /* Ensure the text is on one line */
  line-height: 1.5; /* Adjust line height to fit your design */
  height: 32px; /* Adjust height to fit single line - you can customize this */
}

.ruleGroup {
  background: transparent !important;
  border-style: none !important;
}

.ant-alert-message {
  color: #455560 !important;
}

.ant-alert-description {
  color: #455560 !important;
}

.ant-alert {
  background-color: #f0f7ff !important;
}

.tox .tox-statusbar {
  height: 35px !important;
}
